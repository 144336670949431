import React, { Component } from 'react'

export class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            email: '',
            phone: '',
            city: '',
            empty: false,
            isLoading: false,
            isSended: false
        };
    }

    componentDidMount() {
        fetch("https://www.datos.gov.co/resource/xdk5-pm3f.json")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        cities: result
                    });
                }
            )
    }

    check = (e) => {
        e.preventDefault();
        if (this.state.email === "" || this.state.phone === "" || this.state.city === "") {
            this.setState({
                empty: true
            });
        } else {
            this.setState({
                empty: false
            }, () => this.send());
        }
    }

    send = () => {
        this.setState({
            isLoading: true
        });
        const data = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.email,
                phone: this.state.phone,
                city: this.state.city,
            })
        };

        fetch("https://simplebackend-dmwjvq7ka.now.sh/contact", data)
            .then(
                () => {
                    this.setState({
                        isLoading: false,
                        isSended: true
                    });
                },
                () => {
                    this.setState({
                        isLoading: false,
                        isSended: false
                    });
                }
            )
    }

    render() {
        return (
            <div className="grid container-form-contact">
                {!this.state.isSended ?
                    <form className="form-contact" onSubmit={(e) => this.check(e)}>
                        <h2 className="uppercase title-form">Lorem Ipsum Dolor Sit</h2>
                        <div className="container-inputs">
                            <div className="content-inputs grid">
                                <input className="input-form w-full" type="email" placeholder="Email" required onChange={(e) => this.setState({
                                    email: e.target.value
                                })} />
                                <input className="input-form w-full" type="tel" placeholder="Celular" required onChange={(e) => this.setState({
                                    phone: e.target.value
                                })} />
                                <select className="input-form w-full"
                                    value={this.state.city}
                                    onChange={(e) => this.setState({
                                        city: e.target.value
                                    })} >
                                    <option value={''}>Ciudad</option>
                                    {this.state.cities.map((c, i) =>
                                        <option key={i} value={c.c_digo_dane_del_municipio}>{c.municipio}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        {this.state.empty && <p class="empty-fields">Digite todos los campos</p>}
                        <button className="btn-send" type="submit" disabled={this.state.isLoading}>{this.state.isLoading ? 'ENVIANDO' : 'ENVIAR'}</button>
                    </form>
                    :
                    <div>
                        <h2 className="uppercase title-form">Tu solicitud ha sido enviada!</h2>
                        <p>Pronto nos comunicaremos contigo, gracias por contactarnos :)</p>
                    </div>
                }

                <div className="w-full text-center hide-mobile">
                    <img className="w-full" src="/images/man.jpg" alt="Hombre revisando su telefono" />
                </div>
            </div>
        )
    }
}

export default ContactForm
