import React, { Component } from 'react'

const menuHeader = [
    { name: 'Tiendas', icon: '/images/icons/map.svg' },
    { name: 'Lista de deseos', icon: '/images/icons/fav.svg' },
    { name: 'Mi bolsa', icon: '/images/icons/bag.svg' },
];

export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            showMenu: false
        };
    }
    showMenuLog(t) {
        console.log(`Se hizo clic al menú ${t}`)
    }
    componentDidMount() {
        fetch("https://simplebackend-dmwjvq7ka.now.sh/menu")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        categories: result.data
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <div className="hide-desktop note-offers-mobile flex">
                    <div className="note w-full relative">
                        <span className="block">ENVÍO GRATIS X COMPRA</span>
                    </div>
                    <div className="note w-full relative">
                        <span className="block">SUPERIOR A $150.000</span>
                    </div>
                </div>
                <div className="app-header flex items-center justify-between p-4">
                    <div className="flex-1 relative">
                        <div className={`hide-desktop menu-movil ${this.state.showMenu ? 'open' : ''}`} onClick={() => this.setState({
                            showMenu: !this.state.showMenu
                        })}></div>
                        <div className="note-offers absolute hide-mobile">
                            <div className="note">
                                <span className="block">ENVÍO GRATIS X COMPRA</span>
                            </div>
                            <div className="note">
                                <span className="block">SUPERIOR A $150.000</span>
                            </div>
                        </div>
                    </div>
                    <div className="app-logo flex-1 text-center">
                        <img src="/images/logo.png" alt="Logo" />
                    </div>
                    <div className="flex-1">
                        <div className="flex justify-end">
                            {menuHeader.map((m, i) =>
                                <div key={i} className={`p-2 flex items-center cursor-pointer menu-header ${i === 1 ? 'hide-mobile' : ''}`}>
                                    <div className="relative">
                                        <img src={m.icon} alt={m.name} className="item-menu-header" />
                                        {i === 2 &&
                                            <div className="absolute count-cart">2</div>
                                        }
                                    </div>
                                    <span className="hide-mobile">{m.name}</span>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <div className={`categories flex items-center justify-center mt-2 ${this.state.showMenu ? 'active' : ''}`}>
                    {this.state.categories.map((c, i) =>
                        <div className="category px-4 py-2 cursor-pointer uppercase" onClick={() => this.showMenuLog(c.title)} key={i}>{c.title === 'B�sicos infaltables' ? 'Básicos infaltables' : c.title}</div>
                    )}
                </div>
                <div className="container divider hide-mobile"></div>
            </div>
        )
    }
}
